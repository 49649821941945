const Health = {
    text: 'Home',
    link: '/health',
    icon: 'icon-home',
    pic: 'assets/img/WebIcon/home_unselected.png'
};
const UserList = {
    text: 'User',
    link: '/userlist',
    icon: 'icon-people',
    pic: 'assets/img/WebIcon/user_unselected.png'
};
const PatientList = {
    text: 'Patient',
    link: '/patientlist',
    icon: 'icon-drop',
    pic: 'assets/img/WebIcon/patient_unselected.png'
};
const DevicetList = {
    text: 'Device',
    link: '/devicelist',
    icon: 'icon-drop',
    pic: 'assets/img/WebIcon/icon_Device_01.png'
};
const GatewaynodeList = {
    text: 'Gatewaynode',
    link: '/gatewaynodelist',
    icon: 'icon-drop',
    pic: 'assets/img/WebIcon/medeIot_unselected.png'
};
const Importpatientlist = {
    text: 'ImportPatient',
    link: '/importpatientlist',
    icon: 'icon-drop',
    pic: 'assets/img/WebIcon/import_off.png'
};
const Alluserpatientlist = {
    text: 'AllUserPatient',
    link: '/alluserpatientlist',
    icon: 'icon-drop',
    pic: 'assets/img/WebIcon/patient_list_off.png'
};
//20240219 VA 美國退伍軍人購機方案
const Vauserpatientlist = {
    text: 'VaUserPatient',
    link: '/vauserpatientlist',
    icon: 'icon-drop',
    pic: 'assets/img/WebIcon/patient_list_off.png'
};
const CloudDeviceHistoryList = {
    text: 'CloudDeviceHistory',
    link: '/clouddevicehistorylist',
    icon: 'icon-drop',
    pic: 'assets/img/WebIcon/history_3.png'
};
//20240314 新增disable pressure的序號介面
const DisablePressureSerialList = {
    text: 'DisablePressureSerialList',
    link: '/disablepressureseriallist',
    icon: 'icon-drop',
    pic: 'assets/img/WebIcon/CloudIcon-Disable_1_0.png'
};
const Organization = {
    text: 'Organization',
    link: '/organization',
    icon: 'icon-globe',
    pic: 'assets/img/WebIcon/org_info_unselected.png'
};
const LocationList = {
    text: 'Location',
    link: '/locationlist',
    icon: 'icon-location-pin',
    pic: 'assets/img/WebIcon/location_unselected.png'
};
const Profile = {
    text: 'Profile',
    link: '/profile',
    icon: 'icon-settings',
    pic: 'assets/img/WebIcon/profile_unselected.png'
};
const OrganizationList = {
    text: 'OrganizationList',
    link: '/organizationlist',
    icon: 'icon-globe',
    pic: 'assets/img/WebIcon/orgnization_unselected.png'
};

const OtaList = {
    text: 'OtaList',
    link: '/otalist',
    icon: 'icon-drop',
    pic: 'assets/img/WebIcon/icon_Device_01.png'
};

const ApkList = {
    text: 'ApkList',
    link: '/apklist',
    icon: 'icon-drop',
    pic: 'assets/img/WebIcon/icon_Device_01.png'
};
const Home = {
    text: 'Home',
    link: '/home',
    icon: 'icon-home'
};

const Dashboard = {
    text: 'Dashboard',
    link: '/dashboard',
    icon: 'icon-speedometer',
    submenu: [
        {
            text: 'Dashbord v1',
            link: '/dashboard/v1'
        },
        {
            text: 'Dashbord v2',
            link: '/dashboard/v2'
        },
        {
            text: 'Dashbord v3',
            link: '/dashboard/v3'
        }
    ]
};

const Widgets = {
    text: 'Widgets',
    link: '/widgets',
    icon: 'icon-grid'
};

const Material = {
    text: "Material",
    link: "/material",
    icon: "fa fa-shield-alt",
    submenu: [
        {
            text: "Widgets",
            link: "/material/widgets"
        },
        {
            text: "Cards",
            link: "/material/cards"
        },
        {
            text: "Forms",
            link: "/material/forms"
        },
        {
            text: "Inputs",
            link: "/material/inputs"
        },
        {
            text: "Lists",
            link: "/material/lists"
        },
        {
            text: "Whiteframe",
            link: "/material/whiteframe"
        },
        {
            text: "Colors",
            link: "/material/colors"
        },
        {
            text: "ng2Material",
            link: "/material/ngmaterial"
        }
    ],
    "alert": "new",
    "label": "badge badge-primary"
};

const Elements = {
    text: 'Elements',
    link: '/elements',
    icon: 'icon-chemistry',
    submenu: [
        {
            text: 'Buttons',
            link: '/elements/buttons'
        },
        {
            text: 'Interaction',
            link: '/elements/interaction'
        },
        {
            text: 'Notification',
            link: '/elements/notification'
        },
        {
            text: 'SweetAlert',
            link: '/elements/sweetalert'
        },
        {
            text: 'Spinners',
            link: '/elements/spinners'
        },
        {
            text: 'Dropdown',
            link: '/elements/dropdown'
        },
        {
            text: 'Nav Tree',
            link: '/elements/navtree'
        },
        {
            text: 'Sortable',
            link: '/elements/sortable'
        },
        {
            text: 'Grid',
            link: '/elements/grid'
        },
        {
            text: 'Grid Masonry',
            link: '/elements/gridmasonry'
        },
        {
            text: 'Typography',
            link: '/elements/typography'
        },
        {
            text: 'Font Icons',
            link: '/elements/iconsfont'
        },
        {
            text: 'Weahter Icons',
            link: '/elements/iconsweather'
        },
        {
            text: 'Colors',
            link: '/elements/colors'
        },
        {
            text: 'Infinite Scroll',
            link: '/elements/infinitescroll'
        }
    ]
};

const Forms = {
    text: 'Forms',
    link: '/forms',
    icon: 'icon-note',
    submenu: [
        {
            text: 'Standard',
            link: '/forms/standard'
        },
        {
            text: 'Extended',
            link: '/forms/extended'
        },
        {
            text: 'Validation',
            link: '/forms/validation'
        },
        {
            text: 'Upload',
            link: '/forms/upload'
        },
        {
            text: 'Image Crop',
            link: '/forms/cropper'
        }
    ]
};

const Charts = {
    text: 'Charts',
    link: '/charts',
    icon: 'icon-graph',
    submenu: [
        {
            text: 'Flot',
            link: '/charts/flot'
        },
        {
            text: 'Radial',
            link: '/charts/radial'
        },
        {
            text: 'ChartJS',
            link: '/charts/chartjs'
        }
    ]
};

const Tables = {
    text: 'Tables',
    link: '/tables',
    icon: 'icon-grid',
    submenu: [
        {
            text: 'Standard',
            link: '/tables/standard'
        },
        {
            text: 'Extended',
            link: '/tables/extended'
        },
        {
            text: 'Data-Tables',
            link: '/tables/datatable'
        },
        {
            text: 'Angular Grid',
            link: '/tables/aggrid'
        },
        {
            text: 'NGxDatatables',
            link: '/tables/ngxdatatable'
        }
    ]
};

const Maps = {
    text: 'Maps',
    link: '/maps',
    icon: 'icon-map',
    submenu: [
        {
            text: 'Google',
            link: '/maps/google'
        },
        {
            text: 'Vector',
            link: '/maps/vector'
        }
    ]
};

const Pages = {
    text: 'Pages',
    link: '/pages',
    icon: 'icon-doc',
    submenu: [
        {
            text: 'Login',
            link: '/login'
        },
        // {
        //     text: 'Register',
        //     link: '/register'
        // },
        // {
        //     text: 'Recover',
        //     link: '/recover'
        // },
        // {
        //     text: 'Lock',
        //     link: '/lock'
        // },
        // {
        //     text: '404',
        //     link: '/404'
        // },
        // {
        //     text: '500',
        //     link: '/500'
        // },
        // {
        //     text: 'Maintenance',
        //     link: '/maintenance'
        // }
    ]
};

const Blog = {
    text: 'Blog',
    link: '/blog',
    icon: 'icon-notebook',
    submenu: [
        {
            text: 'List',
            link: '/blog/list'
        },
        {
            text: 'Post',
            link: '/blog/post'
        },
        {
            text: 'Articles',
            link: '/blog/articles'
        },
        {
            text: 'Article View',
            link: '/blog/articleview'
        }
    ]
}

const Ecommerce = {
    text: 'Ecommerce',
    link: '/ecommerce',
    icon: 'icon-basket-loaded',
    submenu: [
        {
            text: 'Orders',
            link: '/ecommerce/orders'
        },
        {
            text: 'Order View',
            link: '/ecommerce/orderview'
        },
        {
            text: 'Products',
            link: '/ecommerce/products'
        },
        {
            text: 'Product View',
            link: '/ecommerce/productview'
        },
        {
            text: 'Checkout',
            link: '/ecommerce/checkout'
        }
    ]
}

const Extras = {
    text: 'Extras',
    link: '/extras',
    icon: 'icon-cup',
    submenu: [
        {
            text: 'Contacts',
            link: '/extras/contacts'
        },
        {
            text: 'Contact details',
            link: '/extras/contactdetails'
        },
        {
            text: 'Projects',
            link: '/extras/projects'
        },
        {
            text: 'Projects details',
            link: '/extras/projectsdetails'
        },
        {
            text: 'Team Viewer',
            link: '/extras/teamviewer'
        },
        {
            text: 'Social Board',
            link: '/extras/socialboard'
        },
        {
            text: 'Vote links',
            link: '/extras/votelinks'
        },
        {
            text: 'Bug tracker',
            link: '/extras/bugtracker'
        },
        {
            text: 'Faq',
            link: '/extras/faq'
        },
        {
            text: 'Help center',
            link: '/extras/helpcenter'
        },
        {
            text: 'Followers',
            link: '/extras/followers'
        },
        {
            text: 'Settings',
            link: '/extras/settings'
        },
        {
            text: 'Plans',
            link: '/extras/plans'
        },
        {
            text: 'File manager',
            link: '/extras/filemanager'
        },
        {
            text: 'Forum',
            link: '/extras/forum'
        },
        {
            text: 'Mailbox',
            link: '/extras/mailbox'
        },
        {
            text: 'Timeline',
            link: '/extras/timeline'
        },
        {
            text: 'Calendar',
            link: '/extras/calendar'
        },
        {
            text: 'Invoice',
            link: '/extras/invoice'
        },
        {
            text: 'Search',
            link: '/extras/search'
        },
        {
            text: 'Todo list',
            link: '/extras/todolist'
        },
        {
            text: 'Profile',
            link: '/extras/profile'
        },
        {
            text: 'Code editor',
            link: '/extras/codeeditor'
        }
    ]
}


const headingMain = {
    text: 'Main Navigation',
    heading: true
};

const headingComponents = {
    text: 'Components',
    heading: true
};

const headingMore = {
    text: 'More',
    heading: true
};

export const menu = [
    Health,
    UserList,
    PatientList,
    Vauserpatientlist,
    DevicetList,
    GatewaynodeList,
    Importpatientlist,
    Alluserpatientlist,
    LocationList,
    Profile,
    Organization,
    OrganizationList,
    CloudDeviceHistoryList,
    DisablePressureSerialList,
    OtaList,
    ApkList,
    // Pages,
    // Extras,
    // headingMain,
    // Home,
    // Dashboard,
    // Widgets,
    //headingComponents,
    // Material,
    // Elements,
    // Forms,
    // Charts,
    // Tables,
    // Maps,
    //headingMore,
    // Blog,
    // Ecommerce
];
