import { LayoutComponent } from '../layout/layout.component';

import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { RecoverComponent } from './pages/recover/recover.component';
import { LockComponent } from './pages/lock/lock.component';
import { MaintenanceComponent } from './pages/maintenance/maintenance.component';
import { Error404Component } from './pages/error404/error404.component';
import { Error500Component } from './pages/error500/error500.component';
import { SdkComponent } from './pages/sdk/sdk.component';
export const routes = [

    {
        path: '',
        component: LoginComponent
    },


    {
        path: '',
        component: LayoutComponent,
        children: [
            { path: '', redirectTo: 'home', pathMatch: 'full' },
            { path: 'health', loadChildren: () => import('./health/health.module').then(m => m.HealthModule) },
            { path: 'userlist', loadChildren: () => import('./userlist/userlist.module').then(m => m.UserlistModule) },
            { path: 'patientlist', loadChildren: () => import('./patientlist/patientlist.module').then(m => m.PatientlistModule) },
            { path: 'devicelist', loadChildren: () => import('./devicelist/devicelist.module').then(m => m.DevicelistModule) },
            { path: 'gatewaynodelist', loadChildren: () => import('./gatewaynodelist/gatewaynodelist.module').then(m => m.GatewaynodelistModule) },
            { path: 'importpatientlist', loadChildren: () => import('./importpatientlist/importpatientlist.module').then(m => m.ImportpatientlistModule) },
            { path: 'alluserpatientlist', loadChildren: () => import('./alluserpatientlist/alluserpatientlist.module').then(m => m.AlluserpatientlistModule) },
            { path: 'vauserpatientlist', loadChildren: () => import('./vauserpatientlist/vauserpatientlist.module').then(m => m.VauserpatientlistModule) },
            { path: 'organization', loadChildren: () => import('./organization/organization.module').then(m => m.OrganizationModule) },
            { path: 'spinner', loadChildren: () => import('./spinner/spinner.module').then(m => m.SpinnerModule) },
            { path: 'locationlist', loadChildren: () => import('./locationlist/locationlist.module').then(m => m.LocationlistModule) },
            { path: 'profile', loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule) },
            { path: 'organizationlist', loadChildren: () => import('./organizationlist/organizationlist.module').then(m => m.OrganizationlistModule) },
            { path: 'clouddevicehistorylist', loadChildren: () => import('./clouddevicehistorylist/clouddevicehistorylist.module').then(m => m.ClouddevicehistorylistModule) },
            { path: 'disablepressureseriallist', loadChildren: () => import('./disablepressureseriallist/disablepressureseriallist.module').then(m => m.DisablepressureseriallistModule) },
            { path: 'otalist', loadChildren: () => import('./otalist/otalist.module').then(m => m.OtalistModule) },
            { path: 'apklist', loadChildren: () => import('./apklist/apklist.module').then(m => m.ApklistModule) },
            { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
            { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) },
            { path: 'widgets', loadChildren: () => import('./widgets/widgets.module').then(m => m.WidgetsModule) },
            { path: 'material', loadChildren: () => import('./material/material.module').then(m => m.MaterialModule) },
            { path: 'elements', loadChildren: () => import('./elements/elements.module').then(m => m.ElementsModule) },
            { path: 'forms', loadChildren: () => import('./forms/forms.module').then(m => m.FormsModule) },
            { path: 'charts', loadChildren: () => import('./charts/charts.module').then(m => m.ChartsModule) },
            { path: 'tables', loadChildren: () => import('./tables/tables.module').then(m => m.TablesModule) },
            { path: 'maps', loadChildren: () => import('./maps/maps.module').then(m => m.MapsModule) },
            { path: 'blog', loadChildren: () => import('./blog/blog.module').then(m => m.BlogModule) },
            { path: 'ecommerce', loadChildren: () => import('./ecommerce/ecommerce.module').then(m => m.EcommerceModule) },
            { path: 'extras', loadChildren: () => import('./extras/extras.module').then(m => m.ExtrasModule) },
            //{ path: 'patient_test', loadChildren: () => import('./patient/patient.module').then(m => m.PatientModule) }
        ]
    },

    // Not lazy-loaded routes
    { path: 'login', component: LoginComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'recover', component: RecoverComponent },
    { path: 'lock', component: LockComponent },
    { path: 'maintenance', component: MaintenanceComponent },
    { path: '404', component: Error404Component },
    { path: 'patient', component: SdkComponent },
    { path: '500', component: Error500Component },

    // Not found
    { path: '**', redirectTo: 'home' }

];
