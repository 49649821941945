import { Injectable, InjectionToken, Inject, Injector} from '@angular/core';
import { UserService } from 'src/app/service/user-service';
import { PatientService } from 'src/app/service/patient-service';
import { GlobalDeviceService } from 'src/app/service/global-device-service';



import { User } from 'src/app/model/user';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { UserDao } from 'src/app/dao/user-dao';
import { PatientDao } from 'src/app/dao/patient-dao';
import { GlobalDeviceDao } from 'src/app/dao/global-device-dao';

import { Patient } from 'src/app/model/patient';
import { UserWebResponse } from 'src/app/model/user-web-response';
import { DeviceSetting } from 'src/app/model/device-setting';
import { Data } from 'src/app/model/data';


const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
const apiUrl = 'https://jsonplaceholder.typicode.com/posts/42';

@Injectable({
    providedIn: 'root'
})
export class GlobalDeviceServiceImpl implements GlobalDeviceService {
    globalDeviceDao: GlobalDeviceDao = this.injector.get(GlobalDeviceDao);
    
    constructor(private injector: Injector,private http:HttpClient) {}

    getGlobalDeviceList(): Observable<any> {
        return this.globalDeviceDao.getGlobalDeviceList();
    }

    getGatewaynodeList(): Observable<any> {
        return this.globalDeviceDao.getGatewaynodeList();
    }

    getOtaById(ota_upload_id): Observable<any> {
        return this.globalDeviceDao.getOtaById(ota_upload_id);
    }

    getOtaList(): Observable<any> {
        return this.globalDeviceDao.getOtaList();
    }

    getApkList(): Observable<any> {
        return this.globalDeviceDao.getApkList();
    }

    addOta(otaUpload): Observable<any> {
        return this.globalDeviceDao.addOta(otaUpload);
    }

    addApk(apkUpload): Observable<any> {
        return this.globalDeviceDao.addApk(apkUpload);
    }

    getImportPatientList(): Observable<any> {
        return this.globalDeviceDao.getImportPatientList();
    }

    getAllUserPatientList(): Observable<any> {
        return this.globalDeviceDao.getAllUserPatientList();
    }

    getVaUserPatientList(): Observable<any> {
        return this.globalDeviceDao.getVaUserPatientList();
    }

    importGlobalDevice(file): Observable<any> {
        return this.globalDeviceDao.importGlobalDevice(file);
    }

    importImportPatient(file): Observable<any> {
        return this.globalDeviceDao.importImportPatient(file);
    }

    getCloudDeviceHistoryList(): Observable<any> {
        return this.globalDeviceDao.getCloudDeviceHistoryList();
    }

    getDisablePressureSerialList(): Observable<any> {
        return this.globalDeviceDao.getDisablePressureSerialList();
    }
    
    addGlobalDevice(device): Observable<any> {
        return this.globalDeviceDao.addGlobalDevice(device);
    }

    addGatewaynode(device): Observable<any> {
        return this.globalDeviceDao.addGatewaynode(device);
    }

    deleteGlobalDevice(devicelist): Observable<any> {
        return this.globalDeviceDao.deleteGlobalDevice(devicelist);
    }

    addCloudDeviceHistory(device): Observable<any> {
        return this.globalDeviceDao.addCloudDeviceHistory(device);
    }

    addDisablePressure(device): Observable<any> {
        return this.globalDeviceDao.addDisablePressure(device);
    }

    editCloudDeviceHistory(device): Observable<any> {
        return this.globalDeviceDao.editCloudDeviceHistory(device);
    }

    private handleError<T> (operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
      
          // TODO: send the error to remote logging infrastructure
          console.error(error); // log to console instead
      
          // TODO: better job of transforming error for user consumption
          console.log(`${operation} failed: ${error.message}`);
      
          // Let the app keep running by returning an empty result.
          return of(result as T);
        };
      }

}

// export const UserServiceProvider = new InjectionToken(
//     'UserServiceProvider',
//     { providedIn: 'root', factory: () => new UserServiceImpl(@Inject(ENV) private environment) }
// );
